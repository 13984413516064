




import Vue from 'vue';
import AuthWindow from '@/components/AuthWindow.vue';

import { User } from '@/types/User';

export default Vue.extend({
    name: 'auth',
    created() {
        if (this.user.id) {
            this.$router.push('/postback');
        }
    },
    computed: {
        user(): User {
            return this.$store.state.user.info;
        }
    },
    components: {
        AuthWindow
    }
});
